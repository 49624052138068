export function tableHeaders() {
    var propType = $("#propertyTypeSelector option:selected").val();
    if (!propType) {
      propType = 'A'
    }

    var tableHeader = $("#listingsDataTable thead tr");
    if (tableHeader.length > 0) {
      // No need to make this call if the table header is not on the page (list vs summary)
      let selectorControl = $('.selected-view');
      let viewId = null;
      if (selectorControl.length > 0)
        viewId = selectorControl.attr('data-view-id');
      else {
        let defaultViewAttrHolder = $('[data-default-view-id]')
        if (defaultViewAttrHolder.length > 0) {
          viewId = defaultViewAttrHolder.attr('data-default-view-id')
        }
      }
      if(viewId){
        $.ajax({
          url: 'views/' + viewId + '?property_type=' + propType,
          dataType: 'json',
          success: function(d){
            tableHeader.find('th:not(.preserve)').remove(); // MLS #, Status, and Price are always preserved in the table
            $(d).each(function(i,tableTitle){
              tableHeader.append('<th>' + tableTitle + '</th>')
            });
          },
          error: function(e){
            console.log('error' + JSON.stringify(e))
          }
        })
      }
    } 
  }
